<template>
  <v-row>
    <v-col>
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :headers="table.headers"
            :items="allStocks"
            :search="table.search"
          >
            <template v-slot:top>
              <v-autocomplete
                label="Member Name"
                :items="allMembers"
                item-text="name"
                item-value="website_id"
                v-model="table.search"
              ></v-autocomplete>
            </template>

            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | formatDate }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-menu left>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list nav dense>
                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" link @click="editData(item)">
                        <v-icon>mdi-file-edit</v-icon>
                      </v-list-item>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip left transition="slide-x-reverse-transition">
                    <template v-slot:activator="{ on }">
                      <v-list-item
                        v-on="on"
                        link
                        @click="deleteConfirmation(item)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-list-item>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="edit.dialog" width="500" persistent>
      <v-card :loading="edit.loading" :disabled="edit.loading">
        <v-toolbar flat>
          <v-toolbar-title> Edit Stock Member </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-form ref="editStock" v-if="edit.data">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Product"
                  :items="products"
                  item-text="name"
                  item-value="name"
                  v-model="edit.data.product_name"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quantity"
                  v-model.number="edit.data.quantity"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn color="indigo accent-2" @click="saveData">
              <span class="white--text">save</span>
              <v-icon right color="white">mdi-content-save</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="500" persistent>
      <v-alert class="mb-0" prominent dark icon="mdi-alert" color="warning">
        <v-row no-gutters class="align-center">
          Are you sure want to delete this?
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleDelete"
            :loading="del.loading"
            :disabled="del.loading"
          >
            <span>ya</span>
          </v-btn>
          <v-btn depressed class="ml-2" @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      table: {
        search: null,
        headers: [
          { text: "Member Name", value: "member.name" },
          { text: "Website ID", value: "member.website_id" },
          { text: "Product Name", value: "product_name", sortable: false },
          { text: "Quantity", value: "quantity", sortable: false },
          { text: "Last Update", value: "updated_at" },
          { text: "Action", value: "action", sortable: false },
        ],
      },
      products: null,
      edit: {
        dialog: false,
        loading: false,
        data: null,
      },
      del: {
        dialog: false,
        loading: false,
        data: null,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateStock", "deleteStock"]),
    async getProducts() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/products`)
        .then((response) => {
          this.products = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editData(item) {
      this.edit.dialog = true;
      this.edit.data = item;
      //   console.log(item)
    },
    saveData() {
      let data = this.edit.data;
      this.edit.loading = true;

      this.updateStock(data)
        .then(() => {
          this.edit.loading = false;
          this.showNotification("success");
        })
        .catch((err) => {
          console.log(err);
          this.edit.loading = false;
          this.showNotification("error");
        });
    },
    deleteConfirmation(item) {
      this.del.data = item;
      this.del.dialog = true;
    },
    handleDelete() {
      let data = this.del.data;
      this.del.loading = true;

      this.deleteStock(data)
        .then(() => {
          this.del.loading = false;
          this.del.dialog = false;
          this.showNotification("success");
        })
        .catch((err) => {
          console.log(err);
          this.del.loading = false;
          this.showNotification("error");
        });
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.text = "Data has been updated successfully";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          this.snackbar.text = "Something went wrong";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allStocks", "allMembers"]),
  },
  filters: {
    formatDate: (value) => {
      return moment(value).format("Do MMM YYYY");
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>